<template>
  <v-container fluid class="pa-3" id="ModalSelecaoPagamento">
      <!-- Modal Body -->

      <!-- Container Form Pagamento -->
      <v-container fluid class="pa-0 modal-wrapper w-100" >
        
        <!-- Form Contact-->
        <h2 align="center" class="pa-0 pt-2" v-bind:style="{ 'color':COR_PRINCIPAL }" >Monte seu plano de Pagamento</h2><br>

        <v-form class="pa-0 pt-0"
                ref="form"
                v-model="valid"
                lazy-validation
        >

        <!-- -- Plano de Pagamento ---------------------------------------- -->
        <v-select
          v-model="selected_plano"
          filled
          dense
          background-color="#F9F9F9"
          icon-color="primary"
          :items="store_Empreendimentos.empreend_selecionado_planos_fase(store_ModalNegociacao.dados.fase)"
          item-text="planovenda_nome"
          item-value="cod_planovenda"
          label="Plano de Pagamento"
          return-object
          @change="changeSelectedPlano"
        >
        <!-- prepend-icon="mdi-content-save" -->
          <!-- <template v-slot:item="{ item, index }">
            <div style="color:#FF0000"><span color="blue" :key="index">{{item.planovenda_nome}}</span></div>
          </template> -->
        </v-select>

        <div v-if="selected_plano" >

          <v-card class="pa-3 pt-0"
                  color="#FFFFFF"
                  elevation="0"
                  outlined
                  style="border: 1px solid #DDDDDD; background-color:#F9F9F9">
              <v-card-title>
                <div style="width:100%; text-align:center ">
                  <span class="mt-1 title-value-light">Entrada</span>
                </div>
              </v-card-title>
              <v-card-text>

                <!-- -- Entrada Total ---------------------------------------------- -->
                <VuetifyMoney
                  v-model="Cliente_Entrada_Total"
                  v-bind:options="options_vuetifymoney"
                  v-bind:label="retorna_PlanoPossibilidades.ENTRADA_TOTAL ? `Entrada Total = ` + formatNumber(Cliente_Entrada_Total_Porcentagem) + `% (mín: R$` + formatNumber(retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo) + ` = ` + formatNumber(this.selected_plano.planovenda_entrada_porc) + `%)`  : ''"
                  v-bind:readonly="retorna_PlanoPossibilidades.ENTRADA_TOTAL ? !retorna_PlanoPossibilidades.ENTRADA_TOTAL.editavel : false"
                  backgroundColor="#F0F0F0"
                  filled
                  :clearable="Cliente_Entrada_Forma_Pgto != 'Somente Sinal'"
                  v-bind:rules="this.retorna_PlanoPossibilidades.ENTRADA_TOTAL ? regraEntradaTotal : []"
                >
                </VuetifyMoney>

                <!-- -- Entrada Forma de Pagamento ---------------------------------------------- -->
                <v-select
                  v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO"
                  filled
                  dense
                  background-color="#F0F0F0"
                  :items="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO.itens_formapagto"
                  label="Forma de Pagamento da Entrada"
                  v-model="Cliente_Entrada_Forma_Pgto"
                ></v-select>

                <v-row>

                <!-- -- Entrada Sinal ---------------------------------------------- -->
                <v-col cols="5">
                  <VuetifyMoney
                    v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO && Cliente_Entrada_Forma_Pgto && Cliente_Entrada_Forma_Pgto != 'Somente Sinal'"
                    v-model="Cliente_Entrada_Sinal"
                    v-bind:options="options_vuetifymoney"
                    backgroundColor="#F0F0F0"
                    v-bind:label="retorna_PlanoPossibilidades.ENTRADA_SINAL ? `Sinal (R$ ` + formatNumber(retorna_PlanoPossibilidades.ENTRADA_SINAL.valor_minimo) + `)` : []"
                    filled
                    dense
                    :clearable="false"
                    v-bind:rules="regraEntradaSinal"
                  >
                  </VuetifyMoney>

                </v-col>

                <!-- -- Restante da Entrada ------------------------------------- -->
                <v-col cols="7" class="d-inline-flex">
                  <span 
                    v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO && Cliente_Entrada_Forma_Pgto && Cliente_Entrada_Forma_Pgto != 'Somente Sinal'"
                    class="mt-1 title-value">+</span>
                  <v-select
                    v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO && Cliente_Entrada_Forma_Pgto && Cliente_Entrada_Forma_Pgto != 'Somente Sinal'"
                    item-text="parcelas_descricao"
                    item-value="parcelas_qtde"
                    filled
                    dense
                    background-color="#F0F0F0"
                    :items="retorna_PlanoPossibilidades.ENTRADA_RESTANTE.itens_formapagto"
                    label="Restante"
                    v-model="Cliente_Entrada_Restante_Item"
                    return-object
                    class="no-wrap"
                  ></v-select>

                </v-col>

                </v-row>

                <!-- -- Totais da Entrada  ---------------------------------------------- -->
                <table  style="border-collapse: collapse; width: 100%;"
                        border="0"
                        v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO && Cliente_Entrada_Forma_Pgto"
                >
                  <tbody>
                    <tr style="width:500px">
                      <td style="width: 100%; text-align:right ">
                        <span style="color:#808080;">Total da Entrada: </span>
                        <span class="title-value-light font-light-bold"> R$ {{ formatPrice(retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc)}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </v-card-text>
          </v-card>
          <br>

          <!-- -- Parcelas  ---------------------------------------------- -->
          <v-card class="pa-3 pt-0"
                  v-if="retorna_PlanoPossibilidades.FORMA_PAGTO < 100"
                  color="#FFFFFF"
                  elevation="0"
                  outlined
                  style="border: 1px solid #DDDDDD; background-color:#F9F9F9">
                  <!-- v-if="retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO && Cliente_Entrada_Forma_Pgto" -->
            <v-card-title>
              <div style="width:100%; text-align:center ">
                  <span class="mt-1 title-value-light">Parcelas</span>
              </div>
            </v-card-title>
            <v-card-text>
              <div style="width:100%; text-align:right ">
                <span style="color:#808080;">Saldo Restante: </span>
                <span class="title-debt font-light-bold"> R$ {{ formatPrice(retorna_PlanoPossibilidades.SALDO_RESTANTE.valor)}}</span>

                <!-- Parcelas para calculo normal -->
                <v-select
                  v-if="!selected_plano.planovenda_formula"
                  v-model="Cliente_Parcela_Selecionada"
                  :items="retorna_PlanoPossibilidades.PARCELAS.itens_formapagto"
                  item-text="parcelas_descricao"
                  item-value="parcelas_qtde"
                  filled
                  dense
                  background-color="#F0F0F0"
                  icon-color="primary"
                  label="Parcelas Mensais"
                  return-object
                >
                </v-select>

                <!-- Parcelas Calculo Vila Olimpica -->
                <v-row
                  v-else>
                    <v-col cols="4" class="d-inline-flex">
                      <v-select
                        v-model="Cliente_Parcela_Selecionada"
                        :items="retorna_PlanoPossibilidades.PARCELAS.itens_formapagto"
                        item-text="parcelas_qtde"
                        item-value="parcelas_qtde"
                        filled
                        dense
                        background-color="#F0F0F0"
                        icon-color="primary"
                        label="Parcelas Mensais"
                        return-object
                      >
                      </v-select>
                    </v-col>

                    <!-- -- Restante das Parcelas ------------------------------------- -->
                    <v-col cols="8" class="d-inline-flex v-align=bottom">
                      <span class="ml-3 mr-3 mt-4 title-value-light"> de: </span>
                      <VuetifyMoney
                        v-model="Cliente_Parcela_Selecionada_Valor"
                        v-bind:options="options_vuetifymoney"
                        backgroundColor="#F0F0F0"
                        label="Valor da Parcela"
                        filled
                        dense
                        :clearable="false"
                        :readonly="true"
                      >
                      </VuetifyMoney>
                    </v-col>
                </v-row>

                <!-- -- Totais da Parcelas  ---------------------------------------------- -->
                <table  style="border-collapse: collapse; width: 100%;"
                        border="0"
                        v-if="Cliente_Parcela_Selecionada"
                >
                  <tbody>
                    <tr style="width:500px">
                      <td style="width: 50%; text-align:right ">
                        <span style="color:#808080;">Total das Parcelas: </span>
                        <span class="title-value-light font-light-bold"> R$ {{ formatPrice(Cliente_Parcela_Selecionada.parcelas_valor_total)}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-card-text>
          </v-card>

          <!-- -- Total Geral ------------------------------------------ -->
          <table  style="border-collapse: collapse; width: 100%;"
                  border="0"
                  v-if="Cliente_Total_Contrato"
          >
            <tbody>
              <tr style="width:500px">
                <td style="width: 50%; text-align:right" class="pr-3 pt-3">
                  <span style="color:#808080;">Total do Contrato: </span>
                  <span class="title-value font-light-bold"> R$ {{ formatPrice(Cliente_Total_Contrato) }}</span>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        </v-form>
      </v-container>

    <!-- End Form Payment-->
    <!-- End Modal Body -->

    <!-- Modal Footer -->
    <v-footer absolute class="pa-2 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary"
             class="mr-2"
             v-on:click="anterior()" outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>

      <v-btn color="primary"
             class="ml-2"
             v-on:click="validate"
             :disabled="!valid"
             :loading="loading">
        <v-icon left>
          mdi-arrow-right
        </v-icon>
        Próximo
      </v-btn>
    </v-footer>

    <!-- End Modal Footer -->
  </v-container>
</template>

<script type="text/javascript">
import Vue from "vue";
import { formatNumber, formatPrice, removeMaskMoney, to_Number } from "../../services/funcoes";
import VuetifyMoney from "../ComponentesGlobais/VuetifyMoney/VuetifyMoney.vue";
import { COR_PRINCIPAL, COR_SECUNDARIA, options_vuetifymoney } from "../../services/constantes"
import { OrcamentoPost } from "../../services/negociacoes"

import {
      C1_calculaPlano,
			C2_calculaEntradaMinimaInicial,
			C3_calculaClienteEntradaTotal,
			C4_calculaEntradaSinalInicial,
			C5_calculaEntradaParcelasRestantesItens,
			C6_calculaEntradaParcelasRestantesInicial,
			C7_calculaEntradaParcelasRestantesCliente,
			//calculaValores,
			//calculaEntradaSinal,
			//calculaClienteEntradaTotal,
			//calculaEntradaParcelasRestantes,
			C8_calculaTotalEntrada,
			C9_calculaSaldo,
			C10_calculaParcela,
			C11_calculaTotalGeral,
      C12_calculaParcelaSelecionada,
} from "../../services/calculo-plano-pagamento";
import store_site from "../../store/store_site"
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";
import moment from 'moment';

export default {
  name: "ModalSelecaoPagamento",

  components: {
    VuetifyMoney
  },

  data() {
    return {
      store_site                  : store_site,
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Empreendimentos       : store_Empreendimentos,

      COR_PRINCIPAL               : COR_PRINCIPAL,
      COR_SECUNDARIA              : COR_SECUNDARIA,
      options_vuetifymoney        : options_vuetifymoney,
      retorna_PlanoPossibilidades : {},

      valid                   : false,
    	timeOut                 : 0,
      formatNumber            : formatNumber,
      formatPrice             : formatPrice,
      removeMaskMoney         : removeMaskMoney,
      to_Number               : to_Number,
      loading                 : false,

      Cliente_Entrada_Total             : null,
      Cliente_Entrada_Total_Porcentagem : 0,
      Cliente_Entrada_Forma_Pgto        : "Somente Sinal",
      Cliente_Entrada_Sinal             : null,
      Cliente_Entrada_Restante          : null,
      Cliente_Entrada_Restante_Item     : null,
      Cliente_Entrada_Restante_Desc     : null,
      Cliente_Entrada_Restante_Qtde     : null,
      Cliente_Parcela_Selecionada       : null,
      Cliente_Parcela_Selecionada_Valor : null,
      Cliente_Total_Contrato            : null,

      selected_plano         : null,

      regraEntradaTotal: [
        (value) => !!value || "O Valor Total da Entrada é obrigatório",
        (value) => (value && to_Number(value)/1 >= to_Number(this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo)/1 ) || "Valor menor que R$ " + formatPrice(this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo) + " não permitido ",
        (value) => (value && to_Number(value)/1 <= to_Number(this.store_ModalNegociacao.dados.preco_final)/1 ) || "Valor maior que R$ " + formatPrice(this.store_ModalNegociacao.dados.preco_final) + " não permitido ",
      ],

      regraEntradaSinal: [
        (value) => !!value || "Valor do Sinal é obrigatório",
        (value) => (value && to_Number(value)/1 >= to_Number(this.retorna_PlanoPossibilidades.ENTRADA_SINAL.valor_minimo)/1 ) || "Valor menor que R$ " + formatPrice(this.retorna_PlanoPossibilidades.ENTRADA_SINAL.valor_minimo) + " não permitido ",
      ],
    };
  },

  created: function () {
    // Limpando orcamento, pois estava dando problema de duplicação de orçamentos nas propostas
    delete this.store_ModalNegociacao.JSON_FORM.orcamento;
    delete this.store_ModalNegociacao.cod_orcamento;

    //(value && to_Number(value)/1 <= to_Number(this.Cliente_Entrada_Total > this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo ? this.Cliente_Entrada_Total : this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo )/1 ) || "Valor maior que R$ " + formatPrice(this.Cliente_Entrada_Total > this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo ? this.Cliente_Entrada_Total : this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo) + " não permitido "

    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, waiting until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce

    //this.debounced_calc_retorna_PlanoPossibilidades = _.debounce(this.calc_retorna_PlanoPossibilidades, 500)
    this.retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO = {};
    this.retorna_PlanoPossibilidades.ENTRADA_FORMA_PGTO.itens_formapagto	= [];
  },

  mounted() {
    //console.log('Mounted - ModalSelecaoPagamento', this.valid, this.selected_plano);
    if (!this.selected_plano)
      this.valid = false;
  },

  watch: {

    async selected_plano() {
      if (this.selected_plano)
        this.valid = true;
      //console.log("WATCH - selected_plano", this.selected_plano)
      
      this.retorna_PlanoPossibilidades        = {};
      this.store_ModalNegociacao.dados.preco_final = this.store_ModalNegociacao.dados.preco_total;
      this.Cliente_Parcela_Selecionada        = null;
      this.Cliente_Parcela_Selecionada_Valor  = null;
      this.Cliente_Entrada_Total              = null;
      this.Cliente_Entrada_Forma_Pgto         = null;
      this.Cliente_Entrada_Sinal              = null;
      this.Cliente_Total_Contrato             = null;
//console.log('1111', this.store_ModalNegociacao.dados.preco_final);
      this.retorna_PlanoPossibilidades  = C1_calculaPlano(this.selected_plano);
//console.log('2222', this.store_ModalNegociacao.dados.preco_final);
      this.retorna_PlanoPossibilidades  = C2_calculaEntradaMinimaInicial( this.selected_plano,
                                                                          this.store_ModalNegociacao.dados
                                                                         );
//console.log('3333', this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total);

      this.store_ModalNegociacao.dados.preco_final = this.retorna_PlanoPossibilidades.TOTAIS.preco_final;
      this.retorna_PlanoPossibilidades  = C2_calculaEntradaMinimaInicial( this.selected_plano,
                                                                          this.store_ModalNegociacao.dados
                                                                         );
      this.Cliente_Entrada_Total        = 0;
      this.$nextTick(() => {
        this.Cliente_Entrada_Total        = this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total;
      });      
      
      this.Cliente_Entrada_Forma_Pgto   = "Somente Sinal";
      this.Cliente_Entrada_Sinal        = this.Cliente_Entrada_Total;

      this.recalcula_Saldo_e_Parcelas();
      this.Cliente_Parcela_Selecionada = {parcelas_qtde : this.selected_plano.planovenda_parcela_quant}
      
    },

    async Cliente_Entrada_Total(val) {
      //console.log("WATCH - Cliente_Entrada_Total", val);
      //this.Cliente_Entrada_Forma_Pgto = "Somente Sinal";
      this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE.item_selecionado = null;
      if (!val) {
        this.Cliente_Entrada_Total = this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo;
        this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total  = this.Cliente_Entrada_Total;
        this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc     = this.Cliente_Entrada_Total;
        this.Cliente_Entrada_Sinal                                            = this.Cliente_Entrada_Total;
      }
      else {
        //console.log("this.Cliente_Entrada_Total", this.Cliente_Entrada_Total, val)
        this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total  = this.Cliente_Entrada_Total;
        this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc     = this.Cliente_Entrada_Total;
        this.retorna_PlanoPossibilidades                                      = C3_calculaClienteEntradaTotal(this.selected_plano, this.store_ModalNegociacao.dados, val);
        if (this.Cliente_Entrada_Forma_Pgto == "Somente Sinal") {
          this.Cliente_Entrada_Sinal                                            = this.Cliente_Entrada_Total;
          this.retorna_PlanoPossibilidades.ENTRADA_SINAL.cliente_entrada_sinal  = this.Cliente_Entrada_Total;
        }
        if (this.selected_plano.planovenda_formula) {
          this.retorna_PlanoPossibilidades = await C12_calculaParcelaSelecionada( this.selected_plano,
                                                                                  this.store_ModalNegociacao.dados,
                                                                                  this.Cliente_Entrada_Total,
                                                                                  this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado.parcelas_qtde);
          this.Cliente_Parcela_Selecionada        = this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado;
          this.Cliente_Parcela_Selecionada_Valor  = this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado.parcelas_valor;
          this.recalcula_Saldo_e_Parcelas();
        }
      }
      this.recalcula_Saldo_e_Parcelas();

      // Calculando a porcentagem de entrada total que o cliente está pagando
      if (this.store_ModalNegociacao.dados.preco_final > 0 && this.Cliente_Entrada_Total)
        this.Cliente_Entrada_Total_Porcentagem = (this.Cliente_Entrada_Total / this.store_ModalNegociacao.dados.preco_final) * 100;

      //console.log("BBB::", this.Cliente_Entrada_Sinal,this.retorna_PlanoPossibilidades.ENTRADA_SINAL.cliente_entrada_sinal);
    },

    async Cliente_Entrada_Forma_Pgto(val) {
      //console.log("WATCH - Cliente_Entrada_Forma_Pgto", val);
      if (val == "Somente Sinal") {
        //console.log('SOMENTE SINAL::', this.Cliente_Entrada_Total, this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total)
        this.retorna_PlanoPossibilidades                                      = C3_calculaClienteEntradaTotal(this.selected_plano, this.store_ModalNegociacao.dados, this.Cliente_Entrada_Total);
        this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE.item_selecionado    = null;
        this.retorna_PlanoPossibilidades                                      = C4_calculaEntradaSinalInicial(this.selected_plano);
        this.Cliente_Entrada_Restante_Item                                    = null;
        this.Cliente_Entrada_Sinal                                            = this.Cliente_Entrada_Total;
        this.retorna_PlanoPossibilidades.ENTRADA_SINAL.cliente_entrada_sinal  = this.Cliente_Entrada_Total;
      }
      else {
        //console.log('SINAIS::', this.Cliente_Entrada_Total, this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.cliente_entrada_total)
        //this.retorna_PlanoPossibilidades    = C3_calculaClienteEntradaTotal(this.Cliente_Entrada_Total);
        this.retorna_PlanoPossibilidades    = C3_calculaClienteEntradaTotal(this.selected_plano, this.store_ModalNegociacao.dados, this.Cliente_Entrada_Total);
        this.retorna_PlanoPossibilidades    = C4_calculaEntradaSinalInicial(this.selected_plano);
        this.Cliente_Entrada_Sinal          = this.retorna_PlanoPossibilidades.ENTRADA_SINAL.cliente_entrada_sinal;
        this.retorna_PlanoPossibilidades    = C5_calculaEntradaParcelasRestantesItens(this.Cliente_Entrada_Total, this.selected_plano, this.Cliente_Entrada_Sinal, this.store_ModalNegociacao.dados);
        this.Cliente_Entrada_Restante_Item  = this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE.item_selecionado;
        this.retorna_PlanoPossibilidades    = C8_calculaTotalEntrada();
      }
      this.recalcula_Saldo_e_Parcelas('PASSOU');
    },

    async Cliente_Entrada_Sinal(val) {
      if (this.Cliente_Entrada_Forma_Pgto == "Somente Sinal") {
        this.Cliente_Entrada_Restante_Item  = null;
        //this.retorna_PlanoPossibilidades.ENTRADA_SINAL = null;
      }
      else if (val) {
        //console.log("WATCH - Cliente_Entrada_Sinal", val, this.Cliente_Entrada_Forma_Pgto);
        this.retorna_PlanoPossibilidades = C5_calculaEntradaParcelasRestantesItens(this.Cliente_Entrada_Total, this.selected_plano, val, this.store_ModalNegociacao.dados);
        this.Cliente_Entrada_Restante_Item  = this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE.item_selecionado;
        this.retorna_PlanoPossibilidades.ENTRADA_SINAL.cliente_entrada_sinal = to_Number(val);
      }
      this.retorna_PlanoPossibilidades = C8_calculaTotalEntrada();
      this.recalcula_Saldo_e_Parcelas();
    },

    async Cliente_Entrada_Restante_Item(val) {
      //console.log("WATCH - Cliente_Entrada_Restante_Item", val);
      this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE.item_selecionado = val;
      this.retorna_PlanoPossibilidades    = C8_calculaTotalEntrada();
      this.recalcula_Saldo_e_Parcelas();      
    },

    async Cliente_Parcela_Selecionada(val) {
      // console.log("1111111111111111111",  val.parcelas_qtde,
      //                                     this.Cliente_Parcela_Selecionada != this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado,
      //                                     this.Cliente_Entrada_Total);
      if (this.selected_plano.planovenda_formula) {
        if (this.Cliente_Parcela_Selecionada != this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado) {
          this.retorna_PlanoPossibilidades = await C12_calculaParcelaSelecionada( this.selected_plano,
                                                                                  this.store_ModalNegociacao.dados,
                                                                                  this.Cliente_Entrada_Total,
                                                                                  val.parcelas_qtde);
        }                                                                           
        this.Cliente_Parcela_Selecionada        = this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado;
        this.Cliente_Parcela_Selecionada_Valor  = this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado.parcelas_valor;
        this.recalcula_Saldo_e_Parcelas();
      }
      else {
        this.retorna_PlanoPossibilidades        = C11_calculaTotalGeral(this.store_ModalNegociacao.dados, val);
        this.Cliente_Total_Contrato             = this.retorna_PlanoPossibilidades.TOTAIS.total_contrato;
      }
    }

  },

  computed: {
    entrada_porcentagemComputed() {
      var ld_Porc = 0;
      if (this.selected_plano) {
        ld_Porc = this.selected_plano.planovenda_entrada_porc;
        //console.log("ld_Porc 1", ld_Porc)
      }
      if (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL &&
          this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total) {
        ld_Porc = (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total / this.store_ModalNegociacao.dados.preco_final) * 100
      }

      this.$nextTick(async function () {
          console.log("ld_Porc 2",  this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc,
                                    this.store_ModalNegociacao.dados.preco_final,
                                    (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc / this.store_ModalNegociacao.dados.preco_final) * 100)
      });
      
      this.$nextTick(async function () {
          console.log("ld_Porc 3",  this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc,
                                    this.store_ModalNegociacao.dados.preco_final,
                                    (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc / this.store_ModalNegociacao.dados.preco_final) * 100)
      });

      this.$nextTick(async function () {
          console.log("ld_Porc 4",  this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc,
                                    this.store_ModalNegociacao.dados.preco_final,
                                    (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc / this.store_ModalNegociacao.dados.preco_final) * 100)
      });

      this.$nextTick(async function () {
          console.log("ld_Porc 5",  this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc,
                                    this.store_ModalNegociacao.dados.preco_final,
                                    (this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.entrada_total_calc / this.store_ModalNegociacao.dados.preco_final) * 100)
      });
      return ld_Porc;
    }
  },

  methods: {

    changeSelectedPlano() {
      //console.log('MUDOU PLANO')
    },

    async recalcula_Saldo_e_Parcelas(value) {
      if (this.selected_plano.planovenda_entrada_porc < 100) {
        //console.log('XCXCXCXCXCXCXC', value, this.selected_plano.planovenda_entrada_porc)
        this.retorna_PlanoPossibilidades = C9_calculaSaldo( this.selected_plano,
                                                            this.store_ModalNegociacao.dados);
        this.retorna_PlanoPossibilidades = C10_calculaParcela(this.selected_plano,
                                                            this.store_ModalNegociacao.dados,
                                                            this.retorna_PlanoPossibilidades.SALDO_RESTANTE.valor);
        if (!this.selected_plano.planovenda_formula)
          this.Cliente_Parcela_Selecionada = this.retorna_PlanoPossibilidades.PARCELAS.item_selecionado;
        this.retorna_PlanoPossibilidades = C11_calculaTotalGeral(this.store_ModalNegociacao.dados, this.Cliente_Parcela_Selecionada);
      }
      else
        this.retorna_PlanoPossibilidades = C11_calculaTotalGeral(this.store_ModalNegociacao.dados, null);
      this.Cliente_Total_Contrato = this.retorna_PlanoPossibilidades.TOTAIS.total_contrato;
      this.store_ModalNegociacao.dados.preco_final = this.retorna_PlanoPossibilidades.TOTAIS.preco_final;
      if (this.retorna_PlanoPossibilidades.TOTAIS.preco_unidade > 0) {
        this.store_ModalNegociacao.dados.preco_final = this.retorna_PlanoPossibilidades.TOTAIS.preco_final;
        Object.assign(this.store_ModalNegociacao.dados.preco_final, this.retorna_PlanoPossibilidades.TOTAIS.preco_final);
        this.store_ModalNegociacao.key_valor++;
      }
      //this.store_ModalNegociacao.dados.preco_desconto = 0;
    },

    anterior() {
      this.store_ModalNegociacao.currentTransition='prev';

      if (this.store_usuario.tipo_cliente == "CLIENTE FINAL")
        this.store_ModalNegociacao.step = 'ModalNegociar';
      else {
        if (this.store_ModalNegociacao.acao == "ORCAMENTO")
          this.store_ModalNegociacao.step = 'ModalSelecaoContato';
        else if (this.store_ModalNegociacao.acao == "PROPOSTA")
          this.store_ModalNegociacao.step = 'ModalSelecaoCorretor';
      }
    },

    async validate () {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {

        var lf_Sinal = !this.retorna_PlanoPossibilidades.ENTRADA_SINAL ? to_Number(this.Cliente_Entrada_Total) : to_Number(this.Cliente_Entrada_Sinal);
        var lo_Orcamento = {
                          //"cod_contato": this.store_usuario.user.cod_usuario,
                          "cod_usuario": this.store_usuario.user.cod_usuario,
                          "cod_imovel": this.store_ModalNegociacao.dados.cod_imovel,
                          "data_entrada_inicial": moment().format('DD/MM/YYYY'),
                          "data_parcela_inicial": null,
                          "planos": [
                            {
                              "cod_planovenda": this.selected_plano.cod_planovenda,
                              "imovel_preco": this.store_ModalNegociacao.dados.preco_total,
                              "imovel_preco_desconto": this.store_ModalNegociacao.dados.desconto_total ?? 0,
                              "imovel_preco_final": this.store_ModalNegociacao.dados.preco_final,
                              "entrada_total": to_Number(this.Cliente_Entrada_Total) ?? 0,
                              "entrada_sinal_obrigatorio": "SIM",
                              "entrada_sinal_valor": to_Number(this.Cliente_Entrada_Sinal),
                              "saldo_restante": this.retorna_PlanoPossibilidades.SALDO_RESTANTE
                                                ? to_Number(this.retorna_PlanoPossibilidades.SALDO_RESTANTE.valor)
                                                : 0,
                              "valor_total" : to_Number(this.Cliente_Total_Contrato),
                              "entrada_minimo_permitido" : to_Number(this.retorna_PlanoPossibilidades.ENTRADA_TOTAL.valor_minimo),
                              "parcelas": [
                                {
                                  "ordem": 1,
                                  "tipo": "SINAL",
                                  "saldo_calcular": 0,
                                  "saldo_restante": 0,
                                  "quantidade": 1,
                                  "valor": lf_Sinal,
                                  "taxa": 0
                                },
                                {
                                  "ordem": 2,
                                  "tipo": "ENTRADA",
                                  "saldo_calcular": 0,
                                  "saldo_restante": 0,
                                  "quantidade": this.Cliente_Entrada_Restante_Item
                                                ? to_Number(this.Cliente_Entrada_Restante_Item?.parcelas_qtde)
                                                : 0,
                                  "valor"     : this.Cliente_Entrada_Restante_Item
                                                ? to_Number(this.Cliente_Entrada_Restante_Item?.parcelas_valor)
                                                : 0,
                                  "taxa"      : this.Cliente_Entrada_Restante_Item
                                                ? to_Number(this.selected_plano.planovenda_entrada_reajuste)
                                                : 0,
                                },
                                {
                                  "ordem": 3,
                                  "tipo": "PARCELA",
                                  "saldo_calcular": 0,
                                  "saldo_restante": 0,
                                  "quantidade": this.retorna_PlanoPossibilidades.PARCELAS
                                                ? to_Number(this.Cliente_Parcela_Selecionada?.parcelas_qtde)
                                                : 0,
                                  "valor"     : this.retorna_PlanoPossibilidades.PARCELAS
                                                ? to_Number(this.Cliente_Parcela_Selecionada?.parcelas_valor)
                                                : 0,
                                  "taxa"      : this.retorna_PlanoPossibilidades.PARCELAS
                                                ? to_Number(this.selected_plano?.planovenda_parcela_reajuste)
                                                : 0,
                                }
                              ]
                            }
                          ]
                        }

        // Recalculando Datas e Entrada e Parcelas
        lo_Orcamento.data_entrada_inicial = moment(lo_Orcamento.data_entrada_inicial, 'DD/MM/YYYY').toDate();
        ////++++ Fernando, Comentado por causa da data errada na proposta
        // if (this.retorna_PlanoPossibilidades.PARCELAS) {
        //   var li_quant = 0;
        //   if (this.retorna_PlanoPossibilidades.ENTRADA_SINAL)
        //     li_quant++;
        //   if (this.retorna_PlanoPossibilidades.ENTRADA_RESTANTE)
        //     li_quant = li_quant + this.Cliente_Entrada_Restante_Qtde;
        //   var dt = moment().add(to_Number(li_quant), 'M').format('DD/MM/YYYY')
        //   lo_Orcamento.data_parcela_inicial = moment(dt, 'DD/MM/YYYY').toDate();;
        // }

        // Verifica se a pessoa é um Cliente ou é um Usuario
        if (this.store_ModalNegociacao.tipo_cliente == "CLIENTE FINAL") {
          lo_Orcamento.cod_pessoa = this.store_usuario.user.cod_pessoa
        }
        else {
          // Selecionou Contato ---------------------------------
          if (this.store_ModalNegociacao.acao == "ORCAMENTO" && this.store_ModalNegociacao.contato_selecionado)
            lo_Orcamento.cod_contato = this.store_ModalNegociacao.contato_selecionado.cod_contato
          else if (this.store_ModalNegociacao.acao == "PROPOSTA" && this.store_ModalNegociacao.pessoa_selecionado) {
            lo_Orcamento.cod_pessoa = this.store_ModalNegociacao.pessoa_selecionado.cod_pessoa
          }
        }
        // Se já iniciou um orçamento, então reenviar as informações mas desta vez somente para aplicar o restante
        //Corrigido, estava duplicando os planos na proposta
        //if (this.store_ModalNegociacao.cod_orcamento) {
        //  lo_Orcamento.cod_orcamento = this.store_ModalNegociacao.cod_orcamento;
        //}

        var lo_Proposta = {
                            "cod_imovel"          : this.store_ModalNegociacao.dados.cod_imovel,
                            "cod_opor"            : null,
                            "cod_usuario"         : this.store_usuario.user.cod_usuario,
                            "cod_pessoa"          : lo_Orcamento.cod_pessoa,
                            "orcamento"           : lo_Orcamento,
                            "plano_venda_1_opcaodiavencto" : this.selected_plano.plano_venda_1_opcaodiavencto,
                            "plano_venda_2_opcaodiavencto" : this.selected_plano.plano_venda_2_opcaodiavencto,
                            "plano_venda_3_opcaodiavencto" : this.selected_plano.plano_venda_3_opcaodiavencto,
                            "plano_venda_4_opcaodiavencto" : this.selected_plano.plano_venda_4_opcaodiavencto,
                            "plano_venda_5_opcaodiavencto" : this.selected_plano.plano_venda_5_opcaodiavencto,
                          }

        // Enviando Proposta ---------------------
        if (this.store_ModalNegociacao.acao == 'PROPOSTA') {

          // Setando Corretor(es)
          if (this.store_ModalNegociacao.corretor_selecionado) {
            lo_Proposta.corretores = [{cod_usuario : this.store_ModalNegociacao.corretor_selecionado.cod_usuario }]
          }

          // Setando Prospeccao
          if (this.store_ModalNegociacao.prospeccao_selecionada.cod_prospeccao) {
            lo_Proposta.cod_prospeccao = this.store_ModalNegociacao.prospeccao_selecionada.cod_prospeccao;
          }

          store_ModalNegociacao.JSON_FORM = lo_Proposta;

          this.store_ModalNegociacao.currentTransition='next';
          this.store_ModalNegociacao.step = 'ModalPropostaConfirmacao';
        }
        // Enviando Orcamento ---------------------
        else if (this.store_ModalNegociacao.acao == 'ORCAMENTO') {

          store_ModalNegociacao.JSON_FORM = lo_Orcamento;

          this.loading = true;

          //console.log("ORCAMENTO - RESP 1 ---  ", store_ModalNegociacao.JSON_FORM)
          const ls_Resp = await OrcamentoPost(store_ModalNegociacao.JSON_FORM);
          //console.log("ORCAMENTO - RESP 2 ---  ", ls_Resp)
          if (ls_Resp.result.message == 'success') {
            this.store_ModalNegociacao.cod_opor       = ls_Resp.result.data.cod_opor;
            this.store_ModalNegociacao.cod_orcamento  = ls_Resp.result.data.cod_orcamento;
            //console.log("this.store_ModalNegociacao.cod_orcamento", this.store_ModalNegociacao.cod_orcamento)
            this.store_site.alert = false;
            this.store_ModalNegociacao.currentTransition='next';
            this.store_ModalNegociacao.step = 'ModalOrcamentoSucesso';
          }
          else {
            this.store_site.alert_cor       = "#FF0000",
            this.store_site.alert_timeout   = 10000,
            this.store_site.alert_msg       = ls_Resp.errors.trim();
            this.store_site.alert           = true;
            this.loading=false;
          }

        }
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },

  }

};

</script>

<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  /* height: auto; */
  /*
  padding: 0px 0px 0px 0px !important; */
  /* background-color: #FF0000; */
  margin-bottom: 60px;
  overflow-y: auto;
}

.title-value {
  font-size: 19px !important;
  font-weight: 800;
  color: steelblue;
}

.title-value-light {
  font-size: 18px !important;
  font-weight: 500;
  color:steelblue;
}

.title-debt {
  font-size: 18px !important;
  font-weight: 800;
  color: #f03347;
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.v-btn {
  width: 150px;
}

.card-row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  padding: 16px;
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #444;
}

</style>
