<template>
  <v-container class="pa-0 modal-wrapper" id="ModalNegociar">

    <!-- Dados ------------------------------------------------------ -->
    <v-container>
      <form @submit="checkForm" >
        <v-layout row wrap class="modal-container w-100 ml-0 pt-4">
          <v-container class="w-100 pa-0 mb-10">
            <v-expansion-panels class="pa-4 pt-0"
              accordion
              :readonly="readonly"
              :multiple="true"
              >
              
              <h2 align="center" style="color:#0548a3">Informações do Imóvel</h2><br>

              <v-expansion-panel class="pl-0 pr-0 accordion-panel" elevation="0">
                <v-expansion-panel-header class="pa-3">
                  Valor:
                  <span v-if="store_usuario.autenticado" class="font-weight-bold ml-1">R$ {{ formatNumber(store_ModalNegociacao.dados.preco_total) }}</span>
                  <span v-else> <v-icon class="ml-1 icon-help">mdi-help-circle-outline</v-icon></span>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pl-4">
                  Valor por m²:
                  <span v-if="store_usuario.autenticado" class="font-weight-bold ml-1">R$ {{ formatNumber(store_ModalNegociacao.dados.preco_metro) }}</span>
                  <span v-else> <v-icon class="icon-help">mdi-help-circle-outline</v-icon></span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="pl-0 pr-0 accordion-panel">
                <v-expansion-panel-header class="pa-3">
                  Metragens e limites:<span class="font-weight-bold ml-1"
                    >{{ formatNumber(store_ModalNegociacao.dados.area_metro) }}m²</span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pl-4">
                  Posição:<span class="font-weight-bold ml-1">{{
                    store_ModalNegociacao.dados.posicao
                  }}</span>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="pl-0 pr-0 accordion-panel">
                <v-expansion-panel-header class="pa-3">
                  Confrontações:
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="justificado font-weight-bold ">{{
                    store_ModalNegociacao.dados.confrontacoes
                  }}</div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="pl-0 pr-0 accordion-panel">
                <v-expansion-panel-header class="pa-3">
                  Outras informações:
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pl-4">
                  <div class="card-body">
                    <p>
                      Caucionado:<span class="font-weight-bold ml-3">{{
                        store_ModalNegociacao.dados.caucionado
                      }}</span>
                    </p>
                    <p>
                      Fase da venda:<span class="font-weight-bold ml-3">{{
                        store_ModalNegociacao.dados.fase
                      }}</span>
                    </p>
                    <p>
                      Permite desmembramento:<span class="font-weight-bold ml-1">{{
                        store_ModalNegociacao.dados.permite_desmembramento
                      }}</span>
                    </p>
                    <p>
                      Localização:<span class="font-weight-bold ml-1">{{
                        store_ModalNegociacao.dados.localizacao
                      }}</span>
                    </p>
                    <p>
                      Utilização:<span class="font-weight-bold ml-1">{{
                        store_ModalNegociacao.dados.utilizacao
                      }}</span>
                    </p>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-layout>

        <!-- Rodape do Cabecalho ---------------------- -->
        <v-footer absolute class="justify-center button-container w-100 pa-2 absolute d-flex ">
          <v-btn
            class="text-none"
            color="primary"
            id="mymodal"
            @click="dialog = true"
          >
            Negociar
          </v-btn>
        </v-footer>

      </form>

    </v-container>
    <!-- ------------------------------  -->

    <!-- Modal Footer -->
    <!-- <div class="button-container w-100 pa-4 absolute d-flex justify-center" > -->
    <!-- Botoes -->
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="440"
        :retain-focus="false"
      >
      <div class="button-container w-100 pa-4 absolute d-flex justify-content-between flex-column align-center">

          <v-btn class="button-container text-none mt-4 mb-4" color="primary" 
            v-if="store_ModalNegociacao.botoes.find(o => (o.opcao == 'proposta')) &&
                  store_usuario.priv_busca_privilegios_empreend_evento(store_ModalNegociacao.dados.cod_empreendimento, 'Proposta.Criar Proposta')"
            v-on:click="store_ModalNegociacao.acao = 'PROPOSTA';Autenticado(2);">
            <v-icon left>
              mdi-file-sign
            </v-icon>
            Enviar Proposta
          </v-btn>

          <v-btn class="button-container text-none mt-4 mb-4" color="yellow"
            v-if="store_ModalNegociacao.botoes.find(o => (o.opcao == 'reserva')) &&
                  store_usuario.priv_busca_privilegios_empreend_evento(store_ModalNegociacao.dados.cod_empreendimento, 'Reserva.Criar Reserva')"
            v-on:click="store_ModalNegociacao.acao = 'RESERVA';Autenticado(3);">
            <v-icon left>
              mdi-map-marker-check-outline
            </v-icon>
            Reservar Unidade
          </v-btn>

          <v-btn class="text-none mt-4 mb-4" color="green"
            v-if="store_ModalNegociacao.botoes.find(o => (o.opcao == 'lead')) &&
                  store_usuario.priv_busca_privilegios_empreend_evento(store_ModalNegociacao.dados.cod_empreendimento, 'Interesse.Criar Interesse')"
            v-on:click="store_ModalNegociacao.acao = 'LEAD'; Autenticado(2);">
            <v-icon left>
              mdi-hand-back-left-outline
            </v-icon>
            Tenho Interesse
          </v-btn>

          <v-btn class="text-none mt-4 mb-4" color="orange"
            v-if="store_ModalNegociacao.botoes.find(o => (o.opcao == 'orçamento')) &&
                  store_usuario.priv_busca_privilegios_empreend_evento(store_ModalNegociacao.dados.cod_empreendimento, 'Simulação.Criar Simulação')"
            v-on:click="store_ModalNegociacao.acao = 'ORCAMENTO'; Autenticado(2);">
            <v-icon left>
              mdi-currency-usd
            </v-icon>
            Simular Valores
          </v-btn>

          <v-btn class="text-none mt-4 mb-4" color="#A0A0A0"
            v-if="store_ModalNegociacao.botoes.find(o => (o.opcao == 'situacao')) &&
                  store_usuario.priv_busca_privilegios_empreend_evento(store_ModalNegociacao.dados.cod_empreendimento, 'Situação.Alterar Situação')"
            v-on:click="store_ModalNegociacao.acao = 'SITUACAO'; Autenticado(2);">
            <v-icon left>
              mdi-order-bool-ascending-variant
            </v-icon>
            Alterar Situação
          </v-btn>
          </div>
      </v-dialog>
    </v-row>


  </v-container>

</template>

<script type="text/javascript">
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_usuario from "../../store/store_usuario";
import store_Login from "../../components/Login/store_Login";
import { formatNumber } from "../../services/funcoes";
import { Autenticado } from "../../services/autenticacao";

export default {
  name: "ModalNegociar",

  data() {
    return {
      formatNumber          : formatNumber,
      store_usuario         : store_usuario,
      store_Login           : store_Login,
      store_ModalNegociacao : store_ModalNegociacao,

      items: [
          { title: "Sair" },
      ],

      readonly  : false,
      drawer    : false,
      dialog    : false,
      tree_opcoes           : [],
      
    };
  },

  // updated: function () {
    //this.store_ModalNegociacao.acao = null;
    // this.$nextTick(async function () {
      // // Code that will run only after the entire view has been re-rendered
      // if (this.store_ModalNegociacao.dados.cod_imovel && this.cod_imovel != this.store_ModalNegociacao.dados.cod_imovel) {
      //   this.cod_imovel = this.store_ModalNegociacao.dados.cod_imovel

      //   this.store_ModalNegociacao.botoes = await OpcaoVendaGet(this.store_ModalNegociacao.dados.cod_imovel);
      //   if (this.store_ModalNegociacao.botoes == "Não possui dados")
      //     this.store_ModalNegociacao.botoes = [];
      // }
    // })
  // },

  mounted() {
    //console.log("Entrou");
    //OpcaoVendaGet(this.store_ModalNegociacao.dados.cod_imovel);
  },

  methods: {

    // selectSection(item) {
    //   this.selectedSection = item;
    //   if (this.selectedSection.title == "Sair") {
    //     console.log('--- SAIR ---');
    //   }

    // },

    Autenticado: async function() {
      const ls_Autenticado = await Autenticado();
      this.store_ModalNegociacao.currentTransition    = 'next';
      this.store_ModalNegociacao.contato_selecionado  = [];
      this.store_ModalNegociacao.pessoa_selecionado   = [];

      // Entrando em Seleção de Pagamento para cliente final ou seleção de cliente para usuários/vendedores  -->
      if (this.store_usuario.tipo_cliente == "CLIENTE FINAL") {
        this.store_ModalNegociacao.step = 'ModalSelecaoPagamento';
      }
      else {
        if (store_ModalNegociacao.acao == 'SITUACAO')
          this.store_ModalNegociacao.step = 'ModalSituacaoImovel';
        else if (store_ModalNegociacao.acao == 'LEAD')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa'; //'ModalReservaConfirmacao';
        else if (store_ModalNegociacao.acao == 'RESERVA')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa'; //'ModalReservaConfirmacao';
        else if (store_ModalNegociacao.acao == 'PROPOSTA')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa';
        else if (store_ModalNegociacao.acao == 'ORCAMENTO')
          this.store_ModalNegociacao.step = 'ModalSelecaoContato';
      }

      this.store_Login.currentTransition='next';
      store_Login.step = 'LoginEntrar';
    },

    checkForm: function (e) {
      //console.log(store_ModalNegociacao.dados);
      e.preventDefault();
    }
  }

};
</script>


<style scoped>
  *,
  html {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    list-style: none;
    font-size: 16px !important;
    text-decoration: none !important;
  }

  .modal-wrapper {
    overflow-y: auto;
    max-height: 340px;
  }

  /* .modal-height {
    overflow-x: hidden;
    min-height: 400px;
  }  */

  .title-value {
    font-size: 18px !important;
    font-weight: 800;
    color: steelblue;
  }

  .title-value-light {
    font-size: 18px !important;
    font-weight: 500;
    color:steelblue;
  }

  .title-debt {
    font-size: 18px !important;
    font-weight: 800;
    color: #f03347;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .v-btn {
    width: 150px;
  }

  .card-row {
    display: flex;
    flex-direction: column;
    box-sizing: border-box !important;
    padding: 16px;
  }

  .card-title {
    font-size: 17px !important;
    font-weight: 600;
    text-transform: capitalize !important;
    color: #444;
  }

  .container-table-contact {
    background: aqua!important;
    padding: 20px;
  }

  .tabela {
    width: 400px!important;
    background: aqua!important;
  }

  .cobre {
    padding: 20px!important;
    background: aqua!important;
  }

  .row-container-contact {
    width: 100%!important;
    padding: 20px!important;
    background: aqua!important;
  }

  .button-container {
    background: #e8e8e8;
    z-index: 999;
  }

  .button-container button {
    width: 220px!important;
  }

  .justificado {
    text-align: justify;
    text-justify: inter-word;
  }

</style>
