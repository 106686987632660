<template>
  <div id="ModalSelecaoCorretor">

    <!-- Begin Componente Usuarios -->
      <CorretorConsultaMobile 
        :cod_empreendimento="$route.params.cod_empreendimento"
      />
    <!-- End Componente Usuarios -->

    <!-- Modal Footer -->
    <v-footer absolute class="pa-2 mt-0 justify-center button-container w-100 d-flex ">
      <v-btn color="primary"
        class="mr-2"      
        v-on:click="Anterior()" 
        outlined>
        <v-icon left>
          mdi-arrow-left
        </v-icon>
        Anterior
      </v-btn>
      
      <v-btn color="primary"
        class="ml-2"
        :disabled="!store_ModalNegociacao.corretor_selecionado || store_ModalNegociacao.corretor_selecionado.length <= 0 || !store_ModalNegociacao.prospeccao_selecionada.cod_prospeccao "
        v-on:click="Proximo()"
        :loading="loading"
      >
        <v-icon left>
          mdi-arrow-right
        </v-icon>
        Próximo
      </v-btn>
    </v-footer>
   
  </div>
</template>

<script type="text/javascript">
import store_usuario from "../../store/store_usuario";
import store_ModalNegociacao from "./store_ModalNegociacao";
import store_Corretor from "../Corretores/store_Corretor";
import CorretorConsultaMobile from "../Corretores/CorretorConsultaMobile.vue"

export default {
  name: "ModalSelecaoCorretor",



  components: {
    CorretorConsultaMobile
  },

  created() {
  },

  data() {
    return {
      store_usuario               : store_usuario,
      store_ModalNegociacao       : store_ModalNegociacao,
      store_Corretor              : store_Corretor,

      timeOut                 : 0,
      loading                 : false,
    };
  },

  methods: {
    Anterior() {
      store_ModalNegociacao.currentTransition='prev';
      store_ModalNegociacao.step = 'ModalSelecaoPessoa';
    },

    Proximo() {
      this.store_ModalNegociacao.currentTransition='next';
      if (this.store_ModalNegociacao.acao == 'LEAD')
        this.store_ModalNegociacao.step = 'ModalLeadConfirmacao'
      else if (this.store_ModalNegociacao.acao == 'RESERVA')
        this.store_ModalNegociacao.step = 'ModalReservaConfirmacao'
      else
        this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'

      // if (this.store_Corretor.tipo_cliente == 'USUARIO')
      //   this.store_ModalNegociacao.step = 'ModalSelecaoCorretor'
      // else
      //   this.store_ModalNegociacao.step = 'ModalSelecaoPagamento'
    },

  }

};

</script>

<style scoped>
*,
html {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  list-style: none;
  font-size: 16px !important;
  text-decoration: none !important;
}

.modal-wrapper {
  overflow-y: auto;
  height: 400px;
}

.title-value {
  font-size: 18px !important;
  font-weight: 800;
  color: steelblue;
}

.title-value-light {
  font-size: 18px !important;
  font-weight: 500;
  color:steelblue;
}

.title-debt {
  font-size: 18px !important;
  font-weight: 800;
  color: #f03347;
}

.button-container {
  background: #e8e8e8;
  z-index: 999;
}

.v-btn {
  width: 150px;
}

.card-row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  padding: 16px;
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #444;
}

.container-table-contact {
  background: aqua!important;
  padding: 20px;
}

.tabela {
  width: 400px!important;
  background: aqua!important;
}

.cobre {
  padding: 20px!important;
  background: aqua!important;
}

.row-container-contact {
  width: 100%!important;
    padding: 20px!important;
    background: aqua!important;
}

</style>
